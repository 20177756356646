<template>
  <div class="home">
    <div class="top">
      <div class="topInner">
        <div class="innerTxt">
          <div class="letfImg">
            <img class="img" src="../assets/imgs/home/logo.png" alt="" />
          </div>
          <div class="rightTzt">
            <ul>
              <li @click="toHome">饰云首页</li>
              <li @click="toAbout">关于企业</li>
              <li @click="toPingtai">平台介绍</li>
              <li class="one">商家入驻</li>
              <li>最新活动</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="bannerTxt">
          <!-- <img class="bannerImg" src="../assets/imgs/about/3.png" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- 文案 -->
    <div class="list5">
      <div class="title">
        <span class="sOne">Platform</span>
        <span class="sTwo">advantages</span>
      </div>
      <div class="title2">平台优势</div>
      <div class="title4">
        <div class="left">
          <img class="img3" src="../assets/imgs/about/14.png" alt="" />
        </div>
        <div class="right">
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              超低的商户平台费：现货供应商5%交易额抽成，
              定制品供应商8%交易额抽成，服务供应商5%交易额抽成。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              众多平台供应商广告位。材料商户入驻平台可以在平台的广告位展示自己的店铺。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              丰富的APP外宣平台推广。公司旗下有自营的头条号、抖音号、公众号。不论服务提供商还是材料供应商都能申请公司的推广。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              专业的团队服务。商户入驻、商户开通网店、网店装修、详情页制作和后台管理等提供一站式专业服务。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              平台专业化系统培训。对设计师及工人进行统一的专业化培训，从而更好的服务于消费者，建立平台良好的口碑
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              现款交易没有账期烦恼。用户确认收货七天后，商户可自行提现每一笔交易款，没有账期。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list6">
      <div class="title">
        <span class="sOne">Supporting</span>
        <span class="sTwo">policies</span>
      </div>
      <div class="title2">扶持政策</div>
      <div class="title4">
        <div class="left">
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              推荐奖励：商户每推荐一家商户，并通过平台审核入驻，平台奖励1000元。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              技术扶持：针对每个商户的网店管理，平台提供特价服务，
              每年收取4980元服务费，帮助商户制作上传商品详情页，广告宣传页，数量不设上限。
            </div>
          </div>
          <div class="box1">
            <img class="boxImg" src="../assets/imgs/about/15.png" alt="" />
            <div class="boxTxt">
              营业额奖励：商户在平台的年销售额达到10万，平台
              奖励1000元。年销售额每增加10万，奖励增加1000
            </div>
          </div>
        </div>
        <div class="right">
          <img class="img3" src="../assets/imgs/about/16.png" alt="" />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <span class="sOne">Enterprise</span>
        <span class="sTwo">strength</span>
      </div>
      <div class="title2">企业实力</div>
      <div class="title3">
        <span class="one">
          <img class="oneImg" src="../assets/imgs/about/17.png" alt="">
          <div class="oneTxt">后台技术团队</div>
        </span>
        <span class="one2">
          <img class="oneImg" src="../assets/imgs/about/18.png" alt="">
          <div class="oneTxt">运营团队</div>
        </span>
        <span class="one2">
          <img class="oneImg" src="../assets/imgs/about/19.png" alt="">
          <div class="oneTxt">一体化服务</div>
        </span>
        <span class="one2">
          <img class="oneImg" src="../assets/imgs/about/20.png" alt="">
          <div class="oneTxt">在线店铺</div>
        </span>
        <span class="one2">
          <img class="oneImg" src="../assets/imgs/about/21.png" alt="">
          <div class="oneTxt">专业广告策划</div>
        </span>
        <span class="one2">
          <img class="oneImg" src="../assets/imgs/about/22.png" alt="">
          <div class="oneTxt">专业服务</div>
        </span>
      </div>
    </div>
    <div class="list2">
      <div class="title">
        <span class="sOne">Contact</span>
        <span class="sTwo">us</span>
      </div>
      <div class="title2">联系我们</div>
      <div class="title3">
        <img src="../assets/imgs/about/23.png" alt="">
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footInfo">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <img
                class="footImg"
                src="../assets/imgs/home/logo.png"
                alt=""
              /></div
          ></el-col>
          <el-col :span="12"
            ><div
              class="grid-content bg-purple-light"
              style="text-align: right"
            >
              <span class="one" @click="toTwo">饰云首页</span>
              <span class="two" @click="toqiye">关于企业</span>
              <span class="three" @click="toThree">平台介绍</span>
              <span class="four">商家入驻</span>
              <span class="five">最新活动</span>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="footer2">
      <div class="footInfo2">
        <div class="footerTop2">
          <el-row>
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <img src="../assets/imgs/home/7.png" alt="" />
                <div class="dizhi">
                  地址：江西省 · 上饶市 · 高铁经济试验区茶圣东路中科数创园3楼
                </div>
                <div class="yingwen">
                  Address: Floor 3, Zhongke digital Chuang garden, Chasheng East
                  Road, high speed Railway Economic Experimental Zone, Shangrao
                  City, Jiangxi Province
                </div>
              </div></el-col
            >
            <el-col :span="12"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img src="../assets/imgs/home/8.png" alt="" />
                <div class="number">191 7037 7053</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer3">
      <div class="footInfo3">
        <div class="footerTop3">
          <el-row>
            <el-col :span="18"
              ><div class="grid-content bg-purple">
                Processed in 0.104075 second(s), 83 queries , Gzip On ©
                2008-2020 飞特网 All rights reserved! 备案编号(
                滇ICP备08100602号 )
              </div></el-col
            >
            <el-col :span="4"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img
                  @click="toScrool"
                  src="../assets/imgs/home/9.png"
                  alt=""
                /></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toAbout() {
      this.$router.push("/About");
    },
    toPingtai() {
      this.$router.push("/Bussiness");
    },
    // 首页
    toTwo() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    toqiye() {
      this.$router.push("/About");
      window.scrollTo(0, 0);
    },
    toThree() {
      this.$router.push("/Bussiness");
      window.scrollTo(0, 0);
    },
    toScrool() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  list-style: none;
}
.home {
  height: 100%;
  .top {
    height: 1080px;
    background: url("../../src/assets/imgs/platform/10.png") no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .topInner {
      width: 100%;
      height: 130px;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      .innerTxt {
        width: 80%;
        height: 130px;
        margin-left: 10%;
        position: relative;
        .letfImg {
          position: absolute;
          top: 25px;
          .img {
            display: inline-block;
            height: 100%;
            width: 100%;
          }
        }
        .rightTzt {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          ul {
            list-style: none;
            li {
              font-size: 20px;
              float: left;
              font-weight: bold;
              margin-left: 40px;
              padding-bottom: 20px;
              cursor: default;
            }
            .one {
              border-bottom: 4px solid #fff;
            }
          }
        }
      }
    }
    .banner {
      height: 950px;
      position: relative;
      .bannerTxt {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .list5 {
    height: 1000px;
    background: #fff;
    padding-top: 100px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
        padding-left: 15px;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title4 {
      display: flex;
      padding-top: 100px;
      .left {
        .img3 {
          width: 917px;
          height: 557px;
        }
      }
      .right {
        margin-left: 130px;
        .box1 {
          padding-top: 30px;
          position: relative;
          .boxImg {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .boxTxt {
            padding-left: 50px;
            width: 524px;
            font-size: 16px;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 28px;
          }
        }
      }
    }
  }
  .list6 {
    height: 1050px;
    background: #f9f9f9;
    padding-top: 100px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
        padding-left: 15px;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title4 {
      display: flex;
      padding-top: 100px;
      .left {
        margin-left: 10%;
        padding-top: 5%;
        .box1 {
          padding-top: 30px;
          position: relative;
          .boxImg {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .boxTxt {
            padding-left: 50px;
            width: 524px;
            font-size: 16px;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 28px;
          }
        }
      }
      .right {
        position: absolute;
        right: 0;
        .img3 {
          width: 917px;
          height: 557px;
        }
      }
    }
  }
  .list {
    height: 780px;
    background: #2a6f6f;
    box-sizing: border-box;
    padding-top: 125px;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #fdc112;
      }
      .sTwo {
        color: #ffffff;
        padding-left: 15px;
      }
    }
    .title2 {
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title3 {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      text-align: center;
      .one {
        margin-top: 160px;
        display: inline-block;
        width: 220px;
        height: 128px;
        background: #ffaa01;
        border-radius: 10px;
        text-align: center;
        padding-top: 35px;
        box-sizing: border-box;
        cursor: default;
        .oneImg{
          width: 36px;
          height: 36px;
        }
        .oneTxt{
          color: #fff;
        }
      }
      .one2 {
        margin-top: 160px;
        display: inline-block;
        width: 220px;
        height: 128px;
        background: #fff;
        border-radius: 10px;
        text-align: center;
        padding-top: 35px;
        box-sizing: border-box;
        cursor: default;
        margin-left: 15px;
        .oneImg{
          width: 36px;
          height: 36px;
        }
        .oneTxt{
          color: #000;
        }
      }
    }
  }
  .list2{
     height: 780px;
    background: #fff;
    box-sizing: border-box;
    padding-top: 125px;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
        padding-left: 15px;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title3{
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      text-align: center;
      margin-top: 70px;
    }
  }
}
.img2 {
  width: 100%;
  height: 100%;
}
.footer {
  height: 100px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #fff;
  color: #fff;
  .footInfo {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;
    .footImg {
      width: 56px;
      height: 50px;
    }
    .one,
    .two,
    .three,
    .four,
    .five {
      font-size: 20px;
    }
    .one {
      padding-right: 40px;
    }
    .two {
      padding-right: 40px;
    }
    .three {
      padding-right: 40px;
    }
    .four {
      padding-right: 40px;
    }
  }
}
.footer2 {
  height: 170px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #595959;
  color: #b0b0b0;
  .footInfo2 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    box-sizing: border-box;
    .footerTop2 {
      .dizhi {
        padding-top: 15px;
        font-size: 16px;
      }
      .yingwen {
        width: 648px;
        height: 44px;
        font-weight: 500;
        color: #6c6c6c;
        line-height: 22px;
      }
      .number {
        padding-top: 30px;
        font-size: 40px;
        color: #fff;
      }
    }
  }
}
.footer3 {
  height: 80px;
  width: 100%;
  background: #323433;
  .footInfo3 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 80px;
    line-height: 80px;
    color: #919191;
    .footerTop3 {
    }
  }
}
.bannerImg {
  height: 100%;
  width: 100%;
}
</style>
