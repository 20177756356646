<template>
  <div class="home">
    <div class="top">
      <div class="topInner">
        <div class="innerTxt">
          <div class="letfImg">
            <img class="img" src="../assets/imgs/home/logo.png" alt="" />
          </div>
          <div class="rightTzt">
            <ul>
              <li class="one">饰云首页</li>
              <li @click="toAbout">关于企业</li>
              <li @click="toPlatform">平台介绍</li>
              <li @click="toBussiness">商家入驻</li>
              <li>最新活动</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="bannerTxt">
          <img class="bannerImg" src="../assets/imgs/home/bannerTxt.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 文案 -->
    <div class="list">
      <div class="listTop">
        <div class="left">
          <img class="img2" src="../assets/imgs/home/4.png" alt="" />
        </div>
        <div class="right">
          <div class="rightOne">饰云科技有限公司</div>
          <div class="rightTwo">Shiyun Technology Co., Ltd</div>
          <div class="rightThree">
            饰云科技由上海饰云互联网信息技术有限公司负责
            技术研发，由上饶市饰云互联网技术有限公司负责落地运营。
          </div>
        </div>
      </div>
      <div class="listTwo">
        <div class="outBox">
          <div class="twoLeft">
            <div class="right">
              <div class="rightOne">技术团队</div>
              <div class="rightTwo">Technical team</div>
              <div class="rightThree">
                上海饰云互联网信息技术有限公司拥有70人+的技术团队，其中包括20人以上的JAVA工程师，
                20人以上的PHP工程师，10人以上运维工程师，10人以上UI工程师，10人以上测试工程师。
              </div>
            </div>
          </div>
          <div class="twoRight">
            <img class="img2" src="../assets/imgs/home/5.png" alt="" />
          </div>
        </div>
      </div>
      <div class="listTop">
        <div class="left">
          <img class="img2" src="../assets/imgs/home/6.png" alt="" />
        </div>
        <div class="right2">
          <div class="rightOne2">运营团队</div>
          <div class="rightTwo2">Operation team</div>
          <div class="rightThree2">
            上饶市饰云互联网技术有限公司拥有80人+的运营团队，
            20人以上的商务BD、30人以上专业客服团队
            10人以上的平面设计、10人以上新媒体运营人员
            5人以上业内知名室内设计师、5人管家培训团队
            5人以上质检培训团队、5人施工工艺培训团队
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footInfo">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" style="display:flex;">
              <div style="display:flex;flex-direction: column;align-items: center;justify-content: center;margin-right:20px;">
                <img
                class="footImg"
                src="../assets/imgs/home/xiazai.png"
                alt=""
              />
              <p style="font-size:14px">扫码下载</p>
              </div>
              <img
                class="footImg"
                src="../assets/imgs/home/logo.png"
                alt=""
              />
              </div
          ></el-col>
          <el-col :span="12"
            ><div
              class="grid-content bg-purple-light"
              style="text-align: right"
            >
              <span class="one">饰云首页</span>
              <span class="two" @click="toTwo">关于企业</span>
              <span class="three" @click="toThree">平台介绍</span>
              <span class="four" @click="toFour">商家入驻</span>
              <span class="five">最新活动</span>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="footer2">
      <div class="footInfo2">
        <div class="footerTop2">
          <el-row>
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <img src="../assets/imgs/home/7.png" alt="" />
                <div class="dizhi">
                  地址：江西省 · 上饶市 · 高铁经济试验区茶圣东路中科数创园3楼
                </div>
                <div class="yingwen">
                  Address: Floor 3, Zhongke digital Chuang garden, Chasheng East
                  Road, high speed Railway Economic Experimental Zone, Shangrao
                  City, Jiangxi Province
                </div>
              </div></el-col
            >
            <el-col :span="12"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img src="../assets/imgs/home/8.png" alt="" />
                <div class="number">191  7037  7053</div>
                </div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer3">
      <div class="footInfo3">
        <div class="footerTop3">
          <el-row>
            <el-col :span="20"
              ><div class="grid-content bg-purple">
                Processed in 0.104075 second(s), 83 queries , Gzip On © 2008-2020 飞特网 All rights reserved!    备案编号( 滇ICP备08100602号 )
              </div></el-col
            >
            <el-col :span="4"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img @click="toScrool" src="../assets/imgs/home/9.png" alt="" />
                </div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    toAbout() {
      this.$router.push("/About");
    },
    toPlatform() {
      this.$router.push("/Bussiness");
    },
    toBussiness() {
      this.$router.push("/Platform");
    },
    toTwo() {
      this.$router.push("/About");
      window.scrollTo(0, 0);
    },
    toThree() {
      this.$router.push("/Bussiness");
      window.scrollTo(0, 0);
    },
    toFour() {
      this.$router.push("/Platform");
      window.scrollTo(0, 0);
    },
    toScrool() {
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style lang="less" scoped>
ul {
  list-style: none;
}
.home {
  height: 100%;
  .top {
    height: 1080px;
    background: url("../../src/assets/imgs/home/banner.png") no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .topInner {
      width: 100%;
      height: 130px;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      .innerTxt {
        width: 80%;
        height: 130px;
        margin-left: 10%;
        position: relative;
        .letfImg {
          position: absolute;
          top: 25px;
          .img {
            display: inline-block;
            height: 100%;
            width: 100%;
          }
        }
        .rightTzt {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          ul {
            list-style: none;
            li {
              font-size: 20px;
              float: left;
              font-weight: bold;
              margin-left: 40px;
              padding-bottom: 20px;
              cursor: default;
            }
            .one {
              border-bottom: 4px solid #fff;
            }
          }
        }
      }
    }
    .banner {
      height: 950px;
      position: relative;
      .bannerTxt {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .list {
    width: 100%;
    // height: 825px;
    // box-sizing: border-box;
    .listTop {
      box-sizing: border-box;
      width: 90%;
      margin-right: 10%;
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
      justify-content: space-between;
      // position: relative;
      .left {
      }
      .right {
        box-sizing: border-box;
        padding-top: 200px;
        // position: absolute;
        // left: 55%;
        .rightOne {
          width: 256px;
          height: 36px;
          font-size: 32px;
          font-weight: 500;
          color: #000000;
          line-height: 36px;
        }
        .rightTwo {
          width: 365px;
          height: 28px;
          font-size: 28px;
          font-weight: 500;
          color: #226a6a;
          line-height: 42px;
          padding-top: 25px;
          padding-bottom: 25px;
        }
        .rightThree {
          width: 596px;
          height: 151px;
          font-size: 28px;
          font-weight: 500;
          color: #a5a5a5;
          line-height: 46px;
          text-align: justify;
        }
      }
      .right2 {
        box-sizing: border-box;
        padding-top: 100px;
        .rightOne2 {
          width: 256px;
          height: 36px;
          font-size: 32px;
          font-weight: 500;
          color: #000000;
          line-height: 36px;
        }
        .rightTwo2 {
          width: 365px;
          height: 28px;
          font-size: 28px;
          font-weight: 500;
          color: #226a6a;
          line-height: 42px;
          padding-top: 25px;
          padding-bottom: 25px;
        }
        .rightThree2 {
          width: 596px;
          height: 151px;
          font-size: 28px;
          font-weight: 500;
          color: #a5a5a5;
          line-height: 46px;
          text-align: justify;
        }
      }
    }
    .listTwo {
      background: #f9f9f9;
      .outBox {
        height: 825px;
        padding-top: 120px;
        padding-bottom: 120px;
        width: 80%;
        margin-left: 20%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        // position: relative;
      }
      .twoLeft {
        padding-top: 100px;
        .rightOne {
          width: 256px;
          height: 36px;
          font-size: 32px;
          font-weight: 500;
          color: #000000;
          line-height: 36px;
        }
        .rightTwo {
          width: 365px;
          height: 28px;
          font-size: 28px;
          font-weight: 500;
          color: #226a6a;
          line-height: 42px;
          padding-top: 25px;
          padding-bottom: 25px;
        }
        .rightThree {
          width: 637px;
          height: 192px;
          font-size: 28px;
          font-weight: 500;
          color: #a5a5a5;
          line-height: 46px;
          text-align: justify;
        }
      }
    }
  }
}
.img2 {
  width: 100%;
  height: 100%;
}
.footer {
  height: 100px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #fff;
  color: #fff;
  .footInfo {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;
    .footImg {
      width: 56px;
      height: 50px;
    }
    .one,
    .two,
    .three,
    .four,
    .five {
      font-size: 20px;
    }
    .one {
      padding-right: 40px;
    }
    .two {
      padding-right: 40px;
    }
    .three {
      padding-right: 40px;
    }
    .four {
      padding-right: 40px;
    }
  }
}
.footer2 {
  height: 170px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #595959;
  color: #b0b0b0;
  .footInfo2 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    box-sizing: border-box;
    .footerTop2 {
      .dizhi {
        padding-top: 15px;
        font-size: 16px;
      }
      .yingwen {
        width: 648px;
        height: 44px;
        font-weight: 500;
        color: #6c6c6c;
        line-height: 22px;
      }
      .number{
        padding-top: 30px;
        font-size: 40px;
        color: #fff;
      }
    }
  }
}
.footer3 {
  height: 80px;
  width: 100%;
  background: #323433;
  .footInfo3 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 80px;
    line-height: 80px;
color: #919191;
    .footerTop3 {
    }
  }
}
.bannerImg{
  height: 100%;
  width: 100%;
}
</style>
