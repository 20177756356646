<template>
  <div class="home">
    <div class="top">
      <div class="topInner">
        <div class="innerTxt">
          <div class="letfImg">
            <img class="img" src="../assets/imgs/home/logo.png" alt="" />
          </div>
          <div class="rightTzt">
            <ul>
              <li @click="toAbout">饰云首页</li>
              <li class="one">关于企业</li>
              <li @click="toPlatform">平台介绍</li>
              <li @click="toBussiness">商家入驻</li>
              <li>最新活动</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="bannerTxt">
          <!-- <img class="bannerImg" src="../assets/imgs/about/3.png" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- 文案 -->
    <div class="list">
      <div class="title">
        <span class="sOne">Company</span>
        <span class="sTwo">profile</span>
      </div>
      <div class="title2">公司简介</div>
      <div class="title3">
        <div class="left">
          <div class="one">饰云科技有限公司</div>
          <div class="two">Shiyun Technology Co., Ltd</div>
          <div class="thr">
            我要装修”APP是上饶市饰云互联网技术有限公司旗下的大型本地服务平台，
            由上海饰云互联网信息技术有限公司提供技术支持。平台通过O2O模式，牵头整合赋能本地商户，本地工人，本地设计师，本地金融等。以拉动本地实体经济为己任，
            以为用户提供安全可靠的装饰施工服务，品类齐全的装饰建材采购，新颖独到的装饰设计为目标。
          </div>
          <div class="four">
            平台有强有力的资金、技术、顾问团队，实力强大。
            平台现阶段估值1.2亿。资本市场对平台看好，前途一片光明。
            我们要做的是利国利民的事，扶持实体经济，给老百姓带来安全可靠的装饰服务。
          </div>
        </div>
        <div class="right">
          <img class="imgRight" src="../assets/imgs/about/1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="list2">
      <div class="title">
        <span class="sOne">Corporate </span>
        <span class="sTwo">culture</span>
      </div>
      <div class="title2">企业文化</div>
      <div class="title4">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <img class="img2" src="../assets/imgs/about/11.png" alt="" /></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <div class="right">
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">共赢——【天道为和，人道为公】</div>
                  <div class="yingTxt">
                    共赢理念是公司文化的核心。平台与合作商共赢，商户与消费者共赢，
                    公司与员工共赢……公司提倡合作，提倡共赢，反对丛林法则的，反人性的恶性竞争行为。
                  </div>
                </span>
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">持续——【人无远虑，必有近忧】</div>
                  <div class="yingTxt">
                    持续理念是与共赢理念相匹配的，相对于短期利益，公司更注重长期发展。不论是公司方面，平台角度，员工，合作商，都要注重可持续发展。
                  </div>
                </span>
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">思辨——【善于思辨，勇于思考】</div>
                  <div class="yingTxt">
                    做事先动脑子后动手，有一个正确的方向远比低头傻干更有效率。思辨的另一方面是勇于思考，敢于思考，勇于挑战权威，敢于推翻现有体制。大到战略方向决策，小到一个具体项目的规划。要尽可能做到深思熟虑，不被固有思维限定。
                  </div>
                </span>
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">务实——【千里之行，始于足下】</div>
                  <div class="yingTxt">
                    路要一步一步走，饭要一口一口吃。不积跬步无以至千里，只有一步一步学习，进步，有真正经得起考验，经得起风雨的硬实力，才能走的更远，站的更久。
                  </div>
                </span>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="list3">
      <div class="title">
        <span class="sOne">Business </span>
        <span class="sTwo">model</span>
      </div>
      <div class="title2">商业模式</div>
      <div class="title3">
        <div class="box">
          <img class="boxImg" src="../assets/imgs/about/10.png" alt="" />
          <div class="txt">
            <div class="txt1">装饰建材+安装服务配套</div>
            <div class="txt2">
              装饰建材作为商品尤其特殊性，就是需要专业安装工，
              传统电商由于是面向全国，商户在当地找的安装工人很难直接沟通，
              培训困难，会导致商品安装人员和商品提供人员很难及时沟通。并且一
              旦商品出现问题需要售后，售后人员根本无法清楚的了解现场实际情况。
              到底是商品问题还是安装问题，还是现场问题，消费者由于专业知识不够，无法判别，导致投诉无门。
            </div>
          </div>
        </div>
        <div class="box">
          <img class="boxImg" src="../assets/imgs/about/8.png" alt="" />
          <div class="txt">
            <div class="txt1">设计+施工服务：</div>
            <div class="txt2">
              装修行业作为一个及其复杂的服务行业，涉及的材料种类成千上万，涉及的工种也
              有几十个。更牵涉到现场尺寸，材料，风水，原建筑结构等很多复杂的现场问题。所以要想把
              整个装修行业搬上互联网，是一件很困难的事情。但是一旦体系完善，平台能做到平稳运转之后，平
              台将会具有极高的用户粘性（三个月的装修，用户一直在和平台打交道）。后期平台开展其他业务，就会极具优势。
            </div>
          </div>
        </div>
        <div class="box">
          <img class="boxImg" src="../assets/imgs/about/7.png" alt="" />
          <div class="txt">
            <div class="txt1">互联网+线下实体+O2O</div>
            <div class="txt2">
              针对装饰建材行业的特殊性，产品更需要线下实体的体验，如果用传统的互联网模式，
              线上推广+仓储+全国物流，很难做到提供给消费者直观的视觉和触觉。更因为建材产品不同批次会出
              现色差，甚至会导致消费者对商家的不信任。色差也会导致补换货困难。所以一定要结合线下工人，线下商户，线上推广三方面。
            </div>
          </div>
        </div>
        <div class="box">
          <img class="boxImg" src="../assets/imgs/about/9.png" alt="" />
          <div class="txt">
            <div class="txt1">施工+建材配套：</div>
            <div class="txt2">
              装饰建材作为商品尤其特殊性，就是需要专业安装工，传统电商由于是面向全国
              ，商和建材+施工配套是类似而不同的体系。建材+施工配套，是针对于门，窗，地板，定
              制柜，窗帘，墙布等等商品价值为主的产品，安装是附加项目。但是在装修中还有以施工为主，材料为辅的
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list4">
      <div class="title">
        <span class="sOne">Platform</span>
        <span class="sTwo">function</span>
      </div>
      <div class="title2">平台功能</div>
      <div class="title3">
        <div class="boxOne">
          <img class="oneImg" src="../assets/imgs/about/4.png" alt="" />
          <div class="boxTit">材料商城板块</div>
          <div class="boxTit2">
            通过商户的实时定位+消费者实时定位，给消费者推荐最近的商家
            ，帮助线下商家大量增加进店客。也给消费者最方便的选择。通过推荐同一
            地域销量号，评价好的商品和销量好，评价好的店铺，让做的好的店铺越做越大。
          </div>
        </div>
        <div class="boxOne boxone2">
          <img class="oneImg" src="../assets/imgs/about/6.png" alt="" />
          <div class="boxTit">设计服务板块</div>
          <div class="boxTit2">
            定期对设计师进行培训，提高平台设计师的设计能力。平台会以合作方式
            低价提供给设计师一些设计软件。提高设计师的工作效率。明码标价，对消费
            者负责到底。平台会对设计师进行审核，也会对设计师的服务进行跟踪，回访。
          </div>
        </div>
        <div class="boxOne boxone3">
          <img class="oneImg" src="../assets/imgs/about/5.png" alt="" />
          <div class="boxTit">工人板块</div>
          <div class="boxTit2">
            通过对工人的培训，提高工人素质。通过各种施工大赛，培养一部分有管理能力
            ，技术过硬的工人，完善培训队伍。通过平台的管家板块，对工地的进度，工程的质量
          </div>
        </div>
      </div>
    </div>
    <div class="list5">
      <div class="title">
        <span class="sOne">Corporate</span>
        <span class="sTwo">culture</span>
      </div>
      <div class="title2">经营理念</div>
      <div class="title4">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <img class="img3" src="../assets/imgs/about/2.png" alt="" /></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <div class="right">
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">改变实体经济被互联网经济支配的现状</div>
                </span>
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">线下实体店仍然是一股强大的力量</div>
                  <div class="yingTxt">
                    <div class="box1">
                    实体店有实物，线上平台只有图片，在购物体感方面。线下实体店体感更好。
                    </div>
                    <div class="box2">
                    线下实体店辐射范围在本市，不像电商辐射全国，在身边的店铺，对于消费者来说不论是信任度，售后沟通的便捷度，产品安装发货的速度，都比传统电商平台根据优势。

                    </div>
                    <div class="box3">
                    线下实体的竞争对手不多。不像电商，数百个同类同款产品在全国范围竞争。平台做的是同城服务，同城货品，竞争不像传统电商那么激烈。不容易形成垄断，对合作商家有更好的保护作用。
                    </div>
                  </div>
                </span>
                <span class="right2">
                  <img
                    class="spanImg"
                    src="../assets/imgs/about/12.png"
                    alt=""
                  />
                  <div class="ying">主要为给消费者带来安全感</div>
                  <div class="yingTxt">
                    <div class="box1">
                    从平台体量上，强大的资金支持，技术团队，给消费者带来安全感。
                    </div>
                    <div class="box2">
                    材料商城方面，完善商品审查，商户监督体制，给消费者带来安全感。
                    </div>
                    <div class="box3">
                    施工工人方面，对工人的培训，施工现场的监督，完工后的回访等，都给消费者带来安全感。
                    </div>
                    <div class="box4">
                    设计师方面，对设计师的培训提高设计师的专业度，对进度的监督改善用户对平台使用的体感，平台对设计师单子的跟踪等等，在设计角度给业主
                    </div>
                  </div>
                </span>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footInfo">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <img
                class="footImg"
                src="../assets/imgs/home/logo.png"
                alt=""
              /></div
          ></el-col>
          <el-col :span="12"
            ><div
              class="grid-content bg-purple-light"
              style="text-align: right"
            >
              <span class="one" @click="toTwo">饰云首页</span>
              <span class="two">关于企业</span>
              <span class="three" @click="toThree">平台介绍</span>
              <span class="four" @click="toFour">商家入驻</span>
              <span class="five">最新活动</span>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="footer2">
      <div class="footInfo2">
        <div class="footerTop2">
          <el-row>
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <img src="../assets/imgs/home/7.png" alt="" />
                <div class="dizhi">
                  地址：江西省 · 上饶市 · 高铁经济试验区茶圣东路中科数创园3楼
                </div>
                <div class="yingwen">
                  Address: Floor 3, Zhongke digital Chuang garden, Chasheng East
                  Road, high speed Railway Economic Experimental Zone, Shangrao
                  City, Jiangxi Province
                </div>
              </div></el-col
            >
            <el-col :span="12"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img src="../assets/imgs/home/8.png" alt="" />
                <div class="number">191 7037 7053</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer3">
      <div class="footInfo3">
        <div class="footerTop3">
          <el-row>
            <el-col :span="18"
              ><div class="grid-content bg-purple">
                Processed in 0.104075 second(s), 83 queries , Gzip On ©
                2008-2020 飞特网 All rights reserved! 备案编号(
                滇ICP备08100602号 )
              </div></el-col
            >
            <el-col :span="4"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img
                  @click="toScrool"
                  src="../assets/imgs/home/9.png"
                  alt=""
                /></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    toAbout() {
      this.$router.push("/");
    },
    toPlatform() {
      this.$router.push("/Bussiness");
    },
    toBussiness() {
      this.$router.push("/Platform");
    },
    toTwo() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    toThree() {
      this.$router.push("/Bussiness");
      window.scrollTo(0, 0);
    },
    toFour() {
      this.$router.push("/Platform");
      window.scrollTo(0, 0);
    },
    toScrool() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  list-style: none;
}
.home {
  height: 100%;
  .top {
    height: 1080px;
    background: url("../../src/assets/imgs/about/3.png") no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .topInner {
      width: 100%;
      height: 130px;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      .innerTxt {
        width: 80%;
        height: 130px;
        margin-left: 10%;
        position: relative;
        .letfImg {
          position: absolute;
          top: 25px;
          .img {
            display: inline-block;
            height: 100%;
            width: 100%;
          }
        }
        .rightTzt {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          ul {
            list-style: none;
            li {
              font-size: 20px;
              float: left;
              font-weight: bold;
              margin-left: 40px;
              padding-bottom: 20px;
              cursor: default;
            }
            .one {
              border-bottom: 4px solid #fff;
            }
          }
        }
      }
    }
    .banner {
      height: 950px;
      position: relative;
      .bannerTxt {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .list {
    height: 1000px;
    background: #fff;
    padding-top: 100px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title3 {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 100px;
      padding-top: 100px;
      box-sizing: border-box;
      position: relative;
      .left {
        width: 80%;
        height: 470px;
        background: #f9f9f9;
        border-radius: 30px;
        padding-left: 82px;
        box-sizing: border-box;
        padding-top: 70px;
        .one {
          font-size: 24px;
          font-weight: 500;
        }
        .two {
          padding: 10px 0;
          color: #8e8c8c;
          font-size: 20px;
        }
        .thr {
          width: 594px;
          // height: 231px;
          font-size: 14px;
          font-weight: 500;
          color: #a5a5a5;
          line-height: 28px;
          text-align: justify;
        }
        .four {
          padding-top: 50px;
          font-size: 14px;
          font-weight: 500;
          color: #a5a5a5;
          width: 594px;
          text-align: justify;
        }
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
        .imgRight {
          width: 690px;
          height: 484px;
        }
      }
    }
  }
  .list2 {
    height: 1000px;
    background: #f9f9f9;
    padding-top: 100px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title4 {
      margin-top: 70px;
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      position: relative;
      .img2 {
        width: 818px;
        height: 514px;
      }
      .right {
        margin-left: 140px;
        .right2 {
          position: relative;
          box-sizing: border-box;
          .spanImg {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 10px;
          }
          .ying {
            padding-left: 40px;
            font-size: 28px;
            font-weight: 500;
            color: #226a6a;
          }
          .yingTxt {
            width: 485px;
            height: 90px;
            font-size: 14px;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 24px;
            text-align: justify;
            padding: 5px 0;
          }
        }
      }
    }
  }
  .list3 {
    height: 1000px;
    background: #fff;
    padding-top: 100px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title3 {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      display: flex;
      flex-wrap: wrap;
      .box {
        width: 40%;
        position: relative;
        height: 300px;
        margin-top: 100px;
        .boxImg {
          width: 100px;
          height: 100px;
        }
        .txt {
          position: absolute;
          top: 0;
          left: 120px;
          .txt1 {
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            padding-bottom: 30px;
          }
          .txt2 {
            width: 90%;
            height: 125px;
            font-size: 14px;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 24px;
            text-align: justify;
          }
        }
      }
      .box:nth-child(2) {
        margin-left: 130px;
      }
      .box:nth-child(4) {
        margin-left: 130px;
      }
    }
  }
  .list4 {
    height: 647px;
    background: #2a6f6f;
    box-sizing: border-box;
    padding-top: 100px;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #fdc112;
      }
      .sTwo {
        color: #ffffff;
      }
    }
    .title2 {
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title3 {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 100px;
      position: relative;
      .boxOne {
        width: 30%;
        height: 541px;
        background: #ffffff;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        display: inline-block;
        text-align: center;
        padding: 75px 45px 70px 45px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        .oneImg {
          width: 100px;
          height: 100px;
        }
        .boxTit {
          padding-top: 60px;
          padding-bottom: 30px;
          font-size: 24px;
          font-weight: 500;
          color: #000000;
        }
        .boxTit2 {
          // width: 343px;
          height: 200px;
          font-size: 18px;
          font-weight: 500;
          color: #a5a5a5;
          line-height: 32px;
          text-align: justify;
          text-justify: center;
          box-sizing: border-box;
        }
      }
      .boxone2 {
        left: 50%;
        transform: translateX(-50%);
      }
      .boxone3 {
        right: 0;
      }
    }
  }
  .list5 {
    height: 1800px;
    background: #fff;
    padding-top: 400px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 52px;
      font-weight: 600;
      .sOne {
        color: #226a6a;
      }
      .sTwo {
        color: #aeaeae;
      }
    }
    .title2 {
      text-align: center;
      color: #000;
      font-weight: 600;
      font-size: 42px;
      padding-top: 40px;
    }
    .title4 {
      margin-top: 70px;
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      position: relative;
      .img3 {
        width: 700px;
        height: 770px;
      }
      .right {
        margin-left: 140px;
        padding-top: 100px;
        .right2 {
          position: relative;
          box-sizing: border-box;
          .spanImg {
            width: 20px;
            height: 20px;
            position: absolute;
            left: -50px;
            top: 10px;
          }
          .ying {
            font-size: 28px;
            font-weight: 500;
            color: #226a6a;
            padding-bottom: 20px;
          }
          .yingTxt {
            width: 597px;
            height: 177px;
            font-size: 14px;
            font-weight: 500;
            color: #a5a5a5;
            line-height: 24px;
            text-align: justify;
            .box1{
              padding-top:5px;
            }
             .box2{
              padding-top:15px;
              padding-bottom: 15px;
            }
             .box3{
              padding-top:15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
.img2 {
  width: 100%;
  height: 100%;
}
.footer {
  height: 100px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #fff;
  color: #fff;
  .footInfo {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;
    .footImg {
      width: 56px;
      height: 50px;
    }
    .one,
    .two,
    .three,
    .four,
    .five {
      font-size: 20px;
    }
    .one {
      padding-right: 40px;
    }
    .two {
      padding-right: 40px;
    }
    .three {
      padding-right: 40px;
    }
    .four {
      padding-right: 40px;
    }
  }
}
.footer2 {
  height: 170px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #595959;
  color: #b0b0b0;
  .footInfo2 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    box-sizing: border-box;
    .footerTop2 {
      .dizhi {
        padding-top: 15px;
        font-size: 16px;
      }
      .yingwen {
        width: 648px;
        height: 44px;
        font-weight: 500;
        color: #6c6c6c;
        line-height: 22px;
      }
      .number {
        padding-top: 30px;
        font-size: 40px;
        color: #fff;
      }
    }
  }
}
.footer3 {
  height: 80px;
  width: 100%;
  background: #323433;
  .footInfo3 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 80px;
    line-height: 80px;
    color: #919191;
    .footerTop3 {
    }
  }
}
.bannerImg {
  height: 100%;
  width: 100%;
}
</style>
