import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Bussiness from '../views/Bussiness.vue'
import Platform from '../views/Platform.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },{
    path: '/Bussiness',
    name: 'Bussiness',
    component: Bussiness
  },{
    path: '/Platform',
    name: 'Platform',
    component: Platform
  }
]

const router = new VueRouter({
  routes
})

export default router
