<template>
  <div style="min-width: 1519px">
    <div class="top">
      <div class="topInner">
        <div class="innerTxt">
          <div class="letfImg">
            <img class="img" src="../assets/imgs/home/logo.png" alt="" />
          </div>
          <div class="rightTzt">
            <ul>
              <li @click="toHome">饰云首页</li>
              <li @click="toAbout">关于企业</li>
              <li class="one">平台介绍</li>
              <li @click="toBussiness">商家入驻</li>
              <li>最新活动</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="bannerTxt">
          <!-- <img class="bannerImg" src="../assets/imgs/about/3.png" alt="" /> -->
        </div>
      </div>
    </div>
    <div style="text-align: center; padding: 97px 0" class="text">
      <span class="Platform">Platform</span>
      <span class="structure">structure</span>
      <div style="font-size: 42px; padding-top: 25px">
        平&nbsp;台&nbsp;结&nbsp;构
      </div>
      <!--  -->
      <div
        style="padding-top: 81px; display: flex; justify-content: space-around"
      >
        <div style="text-align: left">
          <div>
            <span class="circle"></span>
            <div style="display: inline-block; padding-left: 31px">
              <div class="headertex">设计中心板块</div>
              <div class="titletex">设计师：效果图、设计案例、论坛</div>
            </div>
          </div>
          <div style="margin-top: 92px">
            <span class="circle" style="vertical-align: 140%"></span>
            <div style="display: inline-block; padding-left: 31px">
              <div class="headertex">服务中心版块</div>
              <div class="titletex">工人：施工现长、工地照片</div>
              <div class="titletex" style="padding-top: 12px">
                管家：质量检查、安排工期、协调工作
              </div>
            </div>
          </div>
          <div style="margin-top: 92px">
            <span class="circle"></span>
            <div style="display: inline-block; padding-left: 31px">
              <div class="headertex">商城材料版块</div>
              <div class="titletex">店铺：活动、产品</div>
            </div>
          </div>
        </div>
        <div style="float: right">
          <img
            src="../assets/imgs/bussines/structurechart.png"
            style="width: 745px; height: 745px"
          />
        </div>
      </div>
    </div>

    <div style="background-color: #f9f9f9; padding: 97px 0">
      <div style="text-align: center" class="text">
        <span class="Platform">Platform</span>
        <span class="structure">advantages</span>
        <div style="font-size: 42px; padding-top: 25px">
          平&nbsp;台&nbsp;优&nbsp;势
        </div>
      </div>
      <div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 60px;
          "
        >
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon3.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">真实可见</div>
              <div class="advantagebacktitle">
                工地真实可见，随时随地查看无法作假；全网监督，质量有保障
              </div>
            </div>
          </div>
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon2.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">一站式</div>
              <div class="advantagebacktitle">
                我们有一站式服务、全流程快捷的、方便的、安全的、有保障的
              </div>
            </div>
            <div
              style="
                background-color: #226a6a;
                border-radius: 0 0 20px 20px;
                width: 338px;
                height: 17px;
                position: relative;
                top: 35px;
                margin: 0 auto;
              "
            ></div>
          </div>
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon1.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">公开透明</div>
              <div class="advantagebacktitle">
                我们的工价是公开透明的，不怕被入坑，可大胆放心的去查看我们的工价
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 60px;
          "
        >
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon5.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">价格优惠</div>
              <div class="advantagebacktitle">
                建材以及设计价格优惠、优势大、质量可保证、省钱省心的装饰
              </div>
            </div>
          </div>
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon6.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">专业性</div>
              <div class="advantagebacktitle">
                平台拥有专业设计，专业设计师持证上岗，设计独特、有保障
              </div>
            </div>
          </div>
          <div class="advantageback">
            <img src="../assets/imgs/bussines/icon4.png" />
            <div style="display: inline-block; margin-left: 32px">
              <div class="advantagebackheader">保险</div>
              <div class="advantagebacktitle">
                我们的工作人员是拥有保险保护的，不用担心承担一切安全责任
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 97px 0">
      <div style="text-align: center" class="text">
        <span class="Platform">Decoration </span>
        <span class="structure">process</span>
        <div style="font-size: 42px; padding-top: 25px">
          装&nbsp;修&nbsp;流&nbsp;程
        </div>
      </div>
      <div style="margin-top: 73px; text-align: center">
        <img
          src="../assets/imgs/bussines/process.png"
          style="width: 1400px; height: 606px"
        />
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footInfo">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <img
                class="footImg"
                src="../assets/imgs/home/logo.png"
                alt=""
              /></div
          ></el-col>
          <el-col :span="12"
            ><div
              class="grid-content bg-purple-light"
              style="text-align: right"
            >
              <span class="one" @click="toTwo">饰云首页</span>
              <span class="two" @click="toThree">关于企业</span>
              <span class="three">平台介绍</span>
              <span class="four" @click="toFour">商家入驻</span>
              <span class="five">最新活动</span>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="footer2">
      <div class="footInfo2">
        <div class="footerTop2">
          <el-row>
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <img src="../assets/imgs/home/7.png" alt="" />
                <div class="dizhi">
                  地址：江西省 · 上饶市 · 高铁经济试验区茶圣东路中科数创园3楼
                </div>
                <div class="yingwen">
                  Address: Floor 3, Zhongke digital Chuang garden, Chasheng East
                  Road, high speed Railway Economic Experimental Zone, Shangrao
                  City, Jiangxi Province
                </div>
              </div></el-col
            >
            <el-col :span="12"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img src="../assets/imgs/home/8.png" alt="" />
                <div class="number">191 7037 7053</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer3">
      <div class="footInfo3">
        <div class="footerTop3">
          <el-row>
            <el-col :span="18"
              ><div class="grid-content bg-purple">
                Processed in 0.104075 second(s), 83 queries , Gzip On ©
                2008-2020 飞特网 All rights reserved! 备案编号(
                滇ICP备08100602号 )
              </div></el-col
            >
            <el-col :span="4"
              ><div
                class="grid-content bg-purple-light"
                style="text-align: right"
              >
                <img
                  @click="toScrool"
                  src="../assets/imgs/home/9.png"
                  alt=""
                /></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toAbout() {
      this.$router.push("/About");
    },
    toHome() {
      this.$router.push("/");
    },
    toBussiness() {
      this.$router.push("/Platform");
    },
    toTwo() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    toThree() {
      this.$router.push("/About");
      window.scrollTo(0, 0);
    },
    toFour() {
      this.$router.push("/Platform");
      window.scrollTo(0, 0);
    },
    toScrool() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  height: 428px;
  background: url("../../src/assets/imgs/about/24.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  .topInner {
    width: 100%;
    height: 130px;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
    .innerTxt {
      width: 80%;
      height: 130px;
      margin-left: 10%;
      position: relative;
      .letfImg {
        position: absolute;
        top: 25px;
        .img {
          display: inline-block;
          height: 100%;
          width: 100%;
        }
      }
      .rightTzt {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        ul {
          list-style: none;
          li {
            font-size: 20px;
            float: left;
            font-weight: bold;
            margin-left: 40px;
            padding-bottom: 20px;
            cursor: default;
          }
          .one {
            border-bottom: 4px solid #fff;
          }
        }
      }
    }
  }
  .banner {
    height: 950px;
    position: relative;
    .bannerTxt {
      position: absolute;
      top: 230px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.text {
  font-weight: 600;
  font-size: 52px;
  font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
}

.Platform {
  color: #226a6a;
}

.structure {
  color: #aeaeae;
  padding-left: 20px;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #f7b500;
  display: inline-block;
  border-radius: 50%;
  vertical-align: 90%;
}

.headertex {
  font-size: 32px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #226a6a;
}

.titletex {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #b9baba;
  padding-top: 32px;
}

.advantageback {
  width: 406px;
  height: 110px;
  background: #ffffff;
  border-radius: 10px;
  /* line-height: 246px; */
  padding: 52px 24px;
}

.advantageback img {
  width: 86px;
  height: 86px;
  /* vertical-align: middle; */
}

.advantagebackheader {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #226a6a;
}

.advantagebacktitle {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #a5a5a5;
  margin-top: 15px;
  width: 261px;
}

.process {
  width: 294px;
  height: 154px;
  background: #f9f9f9;
  border-radius: 0px 46px;
}

.process div {
  margin: 0 auto;
  width: 204px;
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #2a6f6f;
  position: relative;
  top: 30%;
}

.leftimg {
  width: 50px;
  height: 10px;
}
.footer {
  height: 100px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #fff;
  color: #fff;
  .footInfo {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;
    .footImg {
      width: 56px;
      height: 50px;
    }
    .one,
    .two,
    .three,
    .four,
    .five {
      font-size: 20px;
    }
    .one {
      padding-right: 40px;
    }
    .two {
      padding-right: 40px;
    }
    .three {
      padding-right: 40px;
    }
    .four {
      padding-right: 40px;
    }
  }
}
.footer2 {
  height: 170px;
  width: 100%;
  background: #323433;
  border-bottom: 1px solid #595959;
  color: #b0b0b0;
  .footInfo2 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    box-sizing: border-box;
    .footerTop2 {
      .dizhi {
        padding-top: 15px;
        font-size: 16px;
      }
      .yingwen {
        width: 648px;
        height: 44px;
        font-weight: 500;
        color: #6c6c6c;
        line-height: 22px;
      }
      .number {
        padding-top: 30px;
        font-size: 40px;
        color: #fff;
      }
    }
  }
}
.footer3 {
  height: 80px;
  width: 100%;
  background: #323433;
  .footInfo3 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 80px;
    line-height: 80px;
    color: #919191;
    .footerTop3 {
    }
  }
}
</style>
